import React, { useState, useCallback } from "react"
import { Link } from "gatsby"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

import Layout from "../../components/layout"
import FooterCaption from "../../components/footer-caption"

import One from "../../../content/assets/kitchen-and-bath/1.jpg"
import Two from "../../../content/assets/kitchen-and-bath/2.jpg"
import Three from "../../../content/assets/kitchen-and-bath/3.jpg"
import Four from "../../../content/assets/kitchen-and-bath/4.jpg"
import Five from "../../../content/assets/kitchen-and-bath/5.jpg"
import Six from "../../../content/assets/kitchen-and-bath/6.jpg"
import Seven from "../../../content/assets/kitchen-and-bath/7.jpg"
import Eight from "../../../content/assets/kitchen-and-bath/8.jpg"
import Nine from "../../../content/assets/kitchen-and-bath/9.jpg"
import Ten from "../../../content/assets/kitchen-and-bath/10.jpg"
import Eleven from "../../../content/assets/kitchen-and-bath/11.jpg"

const KitchenBath = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const photos = [
    {
      src: One,
      width: 2,
      height: 1,
      caption: `Fork and Ladle. 20 inches with a 5 1/2 inch bowl.`,
    },
    {
      src: Two,
      width: 1,
      height: 1,
      caption: `Fork and Ladle. 20 inches with a 5 1/2 inch bowl.`,
    },
    {
      src: Three,
      width: 1,
      height: 1,
      caption: `Kitchen Pot Rack, 18" by 32"`,
    },
    {
      src: Four,
      width: 1,
      height: 1,
      caption: `Kitchen Pot Rack, 18" by 32"`,
    },
    {
      src: Five,
      width: 1,
      height: 1,
      caption: `Kitchen Pot Rack, 18" by 32"`,
    },
    {
      src: Six,
      width: 1,
      height: 1,
      caption: `Kitchen Pot Rack, 18" by 32"`,
    },
    {
      src: Seven,
      width: 2,
      height: 1,
      caption: `Kitchen Knife - Rustic`,
    },
    {
      src: Eight,
      width: 2,
      height: 1,
      caption: `Towel Bars and Toilet Paper Holder placed in a log home`,
    },
    {
      src: Nine,
      width: 1,
      height: 1,
      caption: `Towel Bars and Toilet Paper Holder placed in a log home`,
    },
    {
      src: Ten,
      width: 1.5,
      height: 1,
      caption: `Towel Bars and Toilet Paper Holder placed in a log home`,
    },
    {
      src: Eleven,
      width: 1,
      height: 1,
      caption: `Towel Bars and Toilet Paper Holder placed in a log home`,
    }
  ]

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Layout>
      <article>
        <h1>Kitchen & Bath</h1>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                components={{ FooterCaption }}
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <Link
          style={{ display: "inline-block", marginTop: "24px" }}
          to="/gallery"
        >
          ← Back to Gallery
        </Link>
      </article>
    </Layout>
  )
}

export default KitchenBath
